<template>
    <div class="container">
        <div class="col-md-12">
            <div class="offer-dedicated-body-left">
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade active show" id="pills-reviews" role="tabpanel"
                        aria-labelledby="pills-reviews-tab">
                        <div class="bg-white rounded shadow-sm p-4 mb-4 clearfix graph-star-rating">
                            <h5 class="mb-0 mb-4">{{ $t('ratings_and_reviews.your_ratings_and_reviews') }}</h5>

                            <!-- Rating Section -->
                            <div class="rating" v-if="!currentUserRating">
                                <h5 class="mb-4">{{ $t('ratings_and_reviews.rate') }}</h5>
                                <div class="stars">
                                    <span class="fa fa-star" :class="{ 'checked': rating >= 1 }"
                                        @mouseover="setRating(1)"></span>
                                    <span class="fa fa-star" :class="{ 'checked': rating >= 2 }"
                                        @mouseover="setRating(2)"></span>
                                    <span class="fa fa-star" :class="{ 'checked': rating >= 3 }"
                                        @mouseover="setRating(3)"></span>
                                    <span class="fa fa-star" :class="{ 'checked': rating >= 4 }"
                                        @mouseover="setRating(4)"></span>
                                    <span class="fa fa-star" :class="{ 'checked': rating >= 5 }"
                                        @mouseover="setRating(5)"></span>
                                </div>
                            </div>
                            <div class="rating" v-else>
                                <div class="stars">
                                    <span class="fa fa-star" :class="{ 'checked': currentUserRating >= 1 }"></span>
                                    <span class="fa fa-star" :class="{ 'checked': currentUserRating >= 2 }"></span>
                                    <span class="fa fa-star" :class="{ 'checked': currentUserRating >= 3 }"></span>
                                    <span class="fa fa-star" :class="{ 'checked': currentUserRating >= 4 }"></span>
                                    <span class="fa fa-star" :class="{ 'checked': currentUserRating >= 5 }"></span>
                                </div>
                            </div>

                            <!-- Add Photo Section -->
                            <div class="bg-white rounded shadow-sm p-4 mt-4 mb-4" v-if="!currentUserReview">
                                <h5 class="mb-4">Upload Images</h5>
                                <div class="photo-upload">
                                    <!-- File upload button -->
                                    <label for="file-upload" class="upload-button">Upload</label>
                                    <input type="file" id="file-upload" @change="handleFileUpload" multiple
                                        accept="image/*" class="file-input">

                                    <div class="preview-images">
                                        <!-- Show image previews -->
                                        <div class="image-preview" v-for="(image, index) in uploadedImages"
                                            :key="index">
                                            <img :src="image" alt="Image Preview" class="img-thumbnail">
                                            <button type="button" class="btn btn-danger btn-sm"
                                                @click="removeImage(index)">Remove</button>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <!-- Review Section -->
                            <div class="bg-white rounded shadow-sm rating-review-select-page" v-if="!currentUserReview">
                                <h5 class="mb-4 mt-4">{{ $t('ratings_and_reviews.leave_review') }}</h5>
                                <form id="add-review">
                                    <div class="form-group">
                                        <textarea class="form-control" id="review" name="review"
                                            v-model="userReview"></textarea>
                                    </div>
                                    <div class="form-group">
                                        <button class="btn btn-primary btn-sm" @click="saveRating(rating)"
                                            type="button">{{ $t('ratings_and_reviews.submit') }}</button>
                                    </div>
                                </form>
                            </div>

                            <!-- Existing User Review Section -->
                            <div class="bg-white rating-review-select-page" v-else>
                                <p>{{ currentUserReview }}</p>
                            </div>
                        </div>

                        <!-- All Ratings and Reviews Section -->
                        <div class="bg-white rounded shadow-sm p-4 mb-4 restaurant-detailed-ratings-and-reviews">
                            <h5 class="mb-1">{{ $t('ratings_and_reviews.all_ratings_and_reviews') }}</h5>
                            <div class="reviews-members pt-4 pb-4" v-for="(ratingVal, index) in allRatings"
                                :key="index">
                                <div class="media" v-if="currentUserID !== ratingVal.user.id">
                                    <div class="review-icon">
                                        <a href="#"><img alt="Generic placeholder image"
                                                src="http://bootdey.com/img/Content/avatar/avatar1.png"
                                                class="mr-3 rounded-pill"></a>
                                    </div>
                                    <div class="media-body">
                                        <div class="reviews-members-header">
                                            <span class="star-rating float-right">
                                                <a href="#"><i class="icofont-ui-rating active"></i></a>
                                                <a href="#"><i class="icofont-ui-rating active"></i></a>
                                                <a href="#"><i class="icofont-ui-rating active"></i></a>
                                                <a href="#"><i class="icofont-ui-rating active"></i></a>
                                                <a href="#"><i class="icofont-ui-rating"></i></a>
                                            </span>
                                            <h6 class="mb-1"><a class="text-black" href="#">{{ ratingVal.user.name
                                                    }}</a></h6>
                                            <p class="text-gray">{{ ratingVal.created_at }}</p>
                                        </div>
                                        <div class="rating">
                                            <div class="stars">
                                                <span class="fa fa-star"
                                                    :class="{ 'checked': ratingVal.rating >= 1 }"></span>
                                                <span class="fa fa-star"
                                                    :class="{ 'checked': ratingVal.rating >= 2 }"></span>
                                                <span class="fa fa-star"
                                                    :class="{ 'checked': ratingVal.rating >= 3 }"></span>
                                                <span class="fa fa-star"
                                                    :class="{ 'checked': ratingVal.rating >= 4 }"></span>
                                                <span class="fa fa-star"
                                                    :class="{ 'checked': ratingVal.rating >= 5 }"></span>
                                            </div>
                                        </div>
                                        <div class="reviews-members-body">
                                            <p>{{ ratingVal.review }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div v-for="(ratingVal, index) in allRatings" :key="index">
                            <div v-show="ratingVal.rating_images && ratingVal.rating_images.length > 0">
                                <div class="review-images mt-3">
                                    <h6 class="mb-3">Images Shared by {{ ratingVal.user.name }}</h6>
                                    <div class="image-gallery">
                                        <div class="image-item" v-for="(image, imgIndex) in ratingVal.rating_images"
                                            :key="imgIndex">
                                            <img :src="image.image_path" alt="Review Image" class="img-thumbnail" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>
<script>
import API from '@/api'
export default {
    name: 'propertyDetail',
    components: {
    },
    data() {
        return {
            allRatings: {},
            ratingVal: {},
            rating: 0,
            reviews: 0,
            currentUserRating: '',
            currentUserReview: '',
            currentUserID: '',
            userReview: '',
            uploadedImages: []
        }
    },
    mounted() {
        this.getRatingAndReviews();
    },
    methods: {
        handleFileUpload(event) {
            const files = event.target.files;
            if (files) {
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    if (file) {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            this.uploadedImages.push(e.target.result);
                            this.$forceUpdate();
                        };
                        reader.readAsDataURL(file);
                    }
                }
            }
        },
        removeImage(index) {
            this.uploadedImages.splice(index, 1);
        },
        getRatingAndReviews() {
            const propertyId = sessionStorage.getItem('propertyId');
            API.getRatingAndReviews(propertyId, res => {
                this.allRatings = res.data;
                this.currentUserID = res.userID;
                res.data.forEach((value, key) => {
                    value.created_at = this.formatDate(value.created_at);
                    if (res.userID === value.user.id) {
                        this.currentUserRating = value.rating;
                        this.currentUserReview = value.review;
                        this.rating = value.rating;
                        this.userReview = value.review;
                    }
                });
            }, err => {
                console.log(err)
            })
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const year = date.getFullYear();

            return `${month}/${day}/${year}`;
        },
        saveRating(value) {
            this.rating = value;
            const propertyId = sessionStorage.getItem('propertyId');
            this.ratingVal = {
                rating: value,
                propertyId: propertyId,
                review: this.userReview,
                images: this.uploadedImages
            }
            API.saveRating(this.ratingVal, data => {
                if (data.status == 200) {
                    this.currentUserRating = this.rating;
                    this.currentUserReview = this.userReview;
                    this.getRatingAndReviews();
                    this.$swal({
                        type: 'success',
                        title: this.$t('ratings_and_reviews.success'),
                        text: this.$t('ratings_and_reviews.Rating_added_successfully'),
                    })
                } else {
                    this.$swal({
                        type: 'error',
                        title: 'Oops...',
                        text: data.message,
                    })
                }
            }, this.errorCB);
        },
        setRating(value) {
            this.rating = value;
        },

    },
    created() {

    },
}
</script>

<style scoped>
body {
    background: #dcdcdc;
}

.total-like-user-main a {
    display: inline-block;
    margin: 0 -17px 0 0;
}

.total-like {
    border: 1px solid;
    border-radius: 50px;
    display: inline-block;
    font-weight: 500;
    height: 34px;
    line-height: 33px;
    padding: 0 13px;
    vertical-align: top;
}

.restaurant-detailed-ratings-and-reviews hr {
    margin: 0 -24px;
}

.graph-star-rating-header .star-rating {
    font-size: 17px;
}

.progress {
    background: #f2f4f8 none repeat scroll 0 0;
    border-radius: 0;
    height: 30px;
}

.checked,
.price span {
    color: #ff9f1a;
}

.rating-list {
    display: inline-flex;
    margin-bottom: 15px;
    width: 100%;
}

.rating-list-left {
    height: 16px;
    line-height: 29px;
    width: 10%;
}

.rating-list-center {
    width: 80%;
}

.rating-list-right {
    line-height: 29px;
    text-align: right;
    width: 10%;
}

.offer-dedicated-nav .nav-link.active,
.offer-dedicated-nav .nav-link:hover,
.offer-dedicated-nav .nav-link:focus {
    border-color: #3868fb;
    color: #3868fb;
}

.offer-dedicated-nav .nav-link {
    border-bottom: 2px solid #fff;
    color: #000000;
    padding: 16px 0;
    font-weight: 600;
}

.offer-dedicated-nav .nav-item {
    margin: 0 37px 0 0;
}

.restaurant-detailed-action-btn {
    margin-top: 12px;
}

.restaurant-detailed-header-right .btn-success {
    border-radius: 3px;
    height: 45px;
    margin: -18px 0 18px;
    min-width: 130px;
    padding: 7px;
}

.text-black {
    color: #000000;
}

.icon-overlap {
    bottom: -23px;
    font-size: 74px;
    opacity: 0.23;
    position: absolute;
    right: -32px;
}

.menu-list img {
    width: 41px;
    height: 41px;
    object-fit: cover;
}

.restaurant-detailed-header-left img {
    width: 88px;
    height: 88px;
    border-radius: 3px;
    object-fit: cover;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.reviews-members .media .mr-3 {
    width: 56px;
    height: 56px;
    object-fit: cover;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.total-like-user {
    border: 2px solid #fff;
    height: 34px;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
    width: 34px;
}

.total-like-user-main a {
    display: inline-block;
    margin: 0 -17px 0 0;
}

.total-like {
    border: 1px solid;
    border-radius: 50px;
    display: inline-block;
    font-weight: 500;
    height: 34px;
    line-height: 33px;
    padding: 0 13px;
    vertical-align: top;
}

.restaurant-detailed-ratings-and-reviews hr {
    margin: 0 -24px;
}

.graph-star-rating-header .star-rating {
    font-size: 17px;
}

.progress {
    background: #f2f4f8 none repeat scroll 0 0;
    border-radius: 0;
    height: 30px;
}

.rating-list {
    display: inline-flex;
    margin-bottom: 15px;
    width: 100%;
}

.rating-list-left {
    height: 16px;
    line-height: 29px;
    width: 10%;
}

.rating-list-center {
    width: 80%;
}

.rating-list-right {
    line-height: 29px;
    text-align: right;
    width: 10%;
}



.progress {
    background: #f2f4f8 none repeat scroll 0 0;
    border-radius: 0;
    height: 30px;
}

/* Photo upload section */
.photo-upload {
    position: relative;
    margin-bottom: 20px;
}

.upload-button {
    display: inline-block;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 15px;
}

.upload-button:hover {
    background-color: #0056b3;
}

#file-upload {
    display: none;
}

.preview-images {
    margin-top: 15px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.image-preview {
    position: relative;
    display: inline-block;
}

.image-preview img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
}

.image-preview button {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 5px;
}

.image-preview button:hover {
    background-color: darkred;
}








.reviews-members {
    margin-bottom: 30px;
}

.review-images h6 {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
}

.image-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.image-item {
    position: relative;
    width: 100px;
    height: 100px;
    overflow: hidden;
}

.image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.image-item:hover img {
    opacity: 0.8;
    transform: scale(1.1);
    transition: transform 0.3s ease, opacity 0.3s ease;
}
</style>